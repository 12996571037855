// Generated by Framer (1cc6588)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Nd8cnd3kF"];

const serializationHash = "framer-yVUeh"

const variantClassNames = {Nd8cnd3kF: "framer-v-14t6r9a"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, phoneNumber, width, ...props}) => { return {...props, T_mxY_yaU: phoneNumber ?? props.T_mxY_yaU ?? "Phone number"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;phoneNumber?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, T_mxY_yaU, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Nd8cnd3kF", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-yVUeh", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-14t6r9a", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Nd8cnd3kF"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h4 style={{"--font-selector": "R0Y7UG9wcGlucy1yZWd1bGFy", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "135%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-1eung3n, var(--token-5db80294-5bd9-424c-9eb1-ce20ca5c455e, rgb(44, 123, 142)))", "--framer-text-transform": "capitalize"}}>Content</motion.h4></React.Fragment>} className={"framer-14sosu0"} data-framer-name={"contact"} fonts={["GF;Poppins-regular"]} layoutDependency={layoutDependency} layoutId={"uMMUnmuXG"} style={{"--extracted-1eung3n": "var(--token-5db80294-5bd9-424c-9eb1-ce20ca5c455e, rgb(44, 123, 142))", "--framer-paragraph-spacing": "0px"}} text={T_mxY_yaU} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-yVUeh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yVUeh .framer-1ffredc { display: block; }", ".framer-yVUeh .framer-14t6r9a { height: 24px; overflow: hidden; position: relative; width: 354px; }", ".framer-yVUeh .framer-14sosu0 { flex: none; height: auto; left: 50%; position: absolute; top: 50%; white-space: pre-wrap; width: 354px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 354
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"T_mxY_yaU":"phoneNumber"}
 * @framerImmutableVariables true
 */
const FramerinhkOV0DM: React.ComponentType<Props> = withCSS(Component, css, "framer-yVUeh") as typeof Component;
export default FramerinhkOV0DM;

FramerinhkOV0DM.displayName = "contact";

FramerinhkOV0DM.defaultProps = {height: 24, width: 354};

addPropertyControls(FramerinhkOV0DM, {T_mxY_yaU: {defaultValue: "Phone number", title: "Phone number", type: ControlType.String}} as any)

addFonts(FramerinhkOV0DM, [{family: "Poppins", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJXUc1NECPY.woff2", weight: "400"}])